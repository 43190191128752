const translations = Object.assign(
  {},
  require("../../node_modules/front-commerce/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/shipping-mondialrelay-magento1/translations/fr.json"),
  require("../../node_modules/front-commerce/modules/payment-payline-magento1/translations/fr.json"),
  require("../../node_modules/front-commerce/theme-chocolatine/translations/fr.json"),
  require("../../src/translations/fr.json"),
  require("../../translations/fr-FR.json")
);
module.exports = translations;
